import ApolloService from 'ember-apollo-client/services/apollo';
import { createHttpLink } from 'apollo-link-http';
// import { inject as service } from '@ember/service';
// import { isPresent } from '@ember/utils';
import { onError } from 'apollo-link-error';

//import introspectionQueryResultData from 'insurance-quoter/graphql/fragmentTypes';

export default ApolloService.extend({
	// session: service(),

	// cache() {
	//     const fragmentMatcher = new IntrospectionFragmentMatcher({
	//         introspectionQueryResultData
	//     });

	//     return new InMemoryCache({ fragmentMatcher });
	// },

	link() {
		const { apiURL } = this.options;
		const linkOptions = { uri: apiURL, fetch };

		// const token = this.session.get("data.authenticated.access_token");

		// if (isPresent(requestCredentials)) {
		//     linkOptions.credentials = requestCredentials;
		// }

		linkOptions.headers = {
			Authorization: `Bearer Public`,
		};

		// if (isPresent(token)) {
		//     linkOptions.headers = {
		//         Authorization: `Bearer ${token}`
		//     };
		// }

		// const { apiURL } = this.options;

		// const options = {
		//     reconnect: true,
		//     connectionParams: {
		//         headers: {
		//             Authorization: `Bearer Public`
		//         }
		//     }
		// }

		// Afterware
		const errorLink = onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
				);

			if (networkError) console.log(`[Network error]: ${networkError.statusCode} ${networkError}`);

			if (networkError && (networkError.statusCode === 401 || networkError.statusCode === undefined)) {
				this.session.invalidate();
			}
		});

		return errorLink.concat(createHttpLink(linkOptions));
	},
});
