import Route from '@ember/routing/route';
import lrpPrices from 'insurance-quoter/graphql/queries/lrp-prices.graphql';
import { queryManager } from 'ember-apollo-client';
import { action } from '@ember/object';
export default class LrpQuotesRoute extends Route {
	@queryManager apollo;

	queryParams = {
		_commodityCode: { refreshModel: true },
		_commodityTypeCode: { refreshModel: true },
		_stateCode: { refreshModel: true },
	};

	async model(params) {
		const variables = {
			where: {
				stateCode: {
					equals: params._stateCode,
				},
				commodityCode: {
					equals: params._commodityCode,
				},
				typeCode: {
					equals: params._commodityTypeCode,
				},
			},
		};

		return await this.apollo.watchQuery({ query: lrpPrices, variables });
	}

	@action
	async loading(transition) {
		/* eslint-disable */
		let controller = this.controllerFor('lrp-quotes');
		controller.set('currentlyLoading', true);
		transition.promise.finally(function () {
			controller.set('currentlyLoading', false);
		});
	}
}
