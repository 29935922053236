import Route from '@ember/routing/route';
import basisProducts from 'insurance-quoter/graphql/queries/basis-products.graphql';
import { queryManager } from 'ember-apollo-client';
export default class FeedBasisRoute extends Route {
	@queryManager apollo;

	async model() {
		const variables = {
			where: {
				slug: {
					in: ['grain-corn', 'grain-soybean-meal'],
				},
			},
		};

		return await this.apollo.watchQuery({ query: basisProducts, variables });
	}
}
