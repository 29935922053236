import Component from '@glimmer/component';
interface UiButtonArgs {
	size: Size;
	style: Style;
	type: Type;
	width: Width;
	disabled: boolean;
}

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type Style = 'primary' | 'secondary' | 'white' | 'gray' | 'danger';
type Type = 'button' | 'submit' | 'reset';
type Width = 'standard' | 'full';

export default class UiButton extends Component<UiButtonArgs> {
	defaultSize = 'md';
	defaultStyle = 'primary';
	defaultType = 'button';
	defaultWidth = 'standard';
	defaultDisabled = false;

	get size() {
		return this.args.size ?? this.defaultSize;
	}

	get style() {
		return this.args.style ?? this.defaultStyle;
	}

	get type() {
		return this.args.type ?? this.defaultType;
	}

	get width() {
		return this.args.width ?? this.defaultWidth;
	}

	get disabled() {
		return this.args.disabled ?? this.defaultDisabled;
	}
}
