import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n\t<label class='sr-only' for={{@id}} aria-label={{@placeholder}}>{{@srText}}</label>\n\t<input\n\t\tclass='text-brand-gray-90 placeholder-brand-gray-50 border border-brand-gray-50 rounded-md shadow-sm hover:border-brand-interactive-blue-70 hover:bg-brand-gray-10 focus:outline-none focus:border-1 focus:border-brand-interactive-blue-70 transition duration-150 ease-in-out'\n\t\ttype='text'\n\t\tlang='en-150'\n\t\tid={{@id}}\n\t\tplaceholder={{@placeholder}}\n\t\tvalue={{@value}}\n\t\tonInput={{this.onUpdate}}\n\t/>\n</div>", {"contents":"<div>\n\t<label class='sr-only' for={{@id}} aria-label={{@placeholder}}>{{@srText}}</label>\n\t<input\n\t\tclass='text-brand-gray-90 placeholder-brand-gray-50 border border-brand-gray-50 rounded-md shadow-sm hover:border-brand-interactive-blue-70 hover:bg-brand-gray-10 focus:outline-none focus:border-1 focus:border-brand-interactive-blue-70 transition duration-150 ease-in-out'\n\t\ttype='text'\n\t\tlang='en-150'\n\t\tid={{@id}}\n\t\tplaceholder={{@placeholder}}\n\t\tvalue={{@value}}\n\t\tonInput={{this.onUpdate}}\n\t/>\n</div>","moduleName":"insurance-quoter/components/formatted-number-input.hbs","parseOptions":{"srcName":"insurance-quoter/components/formatted-number-input.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class FormattedNumberInputComponent extends Component {
	@action
	onUpdate(e) {
		let v = e.target.value.replace(/,/g, '');
		this.args.update(v);
	}
}
