import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import States from 'insurance-quoter/graphql/queries/states.graphql';
import { queryManager } from 'ember-apollo-client';
import mutation from 'insurance-quoter/graphql/mutations/create-subscription.graphql';
import { inject as service } from '@ember/service';
import { mixpanelCustomTracking } from 'insurance-quoter/utils/mixpanel-tracking';
export default class NotificationsLrpCreateSubscriptionController extends Controller {
	@queryManager apollo;
	@service router;
	commodities = [
		{
			name: 'Feeder Cattle',
			code: '0801',
			commodityTypes: [
				{
					name: 'Steers Weight (< 600 LBS)',
					code: '809',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Steers Weight (600 - 1000 LBS)',
					code: '810',
					min: 6,
					max: 10,
				},
				{
					name: 'Heifers Weight (< 600 LBS)',
					code: '811',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Heifers Weight (600 - 1000 LBS)',
					code: '812',
					min: 6,
					max: 10,
				},
				{
					name: 'Brahman Weight (< 600 LBS)',
					code: '813',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Brahman Weight (600 - 1000 LBS)',
					code: '814',
					min: 6,
					max: 10,
				},
				{
					name: 'Dairy Weight (< 600 LBS)',
					code: '815',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Dairy Weight (600 - 1000 LBS)',
					code: '816',
					min: 6,
					max: 10,
				},
				{
					name: 'Unborn Steers & Heifers',
					code: '817',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Unborn Brahman',
					code: '818',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Unborn Dairy',
					code: '819',
					min: 1,
					max: 5.99,
				},
			],
		},
		{
			name: 'Fed Cattle',
			code: '0802',
			commodityTypes: [
				{
					name: 'Steers & Heifers  (1,000 - 1,600 LBS)',
					code: '820',
					min: 10,
					max: 16,
				},
			],
		},
		{
			name: 'Swine',
			code: '0815',
			commodityTypes: [
				{
					name: 'Unborn Swine',
					code: '821',
					min: 1.5,
					max: 2.25,
				},
				{
					name: 'No Type Specified',
					code: '997',
					min: 1.5,
					max: 2.25,
				},
			],
		},
	];

	@tracked name = null;
	@tracked email = null;
	@tracked statesList = [];
	@tracked stateCode = this.statesList.States?.map((state) => state.rmaStateCode).shift();
	@tracked headCount = 50;
	@tracked targetWeightCWT = 5.0;
	@tracked _commodityCode = this.commodities[0].code;
	@tracked _commodityTypeCode = this.commodities[0].commodityTypes[0].code;
	@tracked hasError = false;

	get commodityCode() {
		return this._commodityCode;
	}

	set commodityCode(code) {
		this._commodityCode = code;
		this._commodityTypeCode = this.commodities.find((commodity) => commodity.code == code)?.commodityTypes[0].code;
		this.targetWeightCWT = this.commodities.find((commodity) => commodity.code == code)?.commodityTypes[0].min;
	}

	get commodityTypeCode() {
		return this._commodityTypeCode;
	}

	set commodityTypeCode(code) {
		this._commodityTypeCode = code;
		this.targetWeightCWT = this.targetWeightMin;
	}

	get commodity() {
		return this.commodities.find((commodity) => commodity.code == this.commodityCode);
	}

	get targetWeightMin() {
		const _commodity = this.commodity;
		const commodityType = _commodity.commodityTypes.find((type) => this._commodityTypeCode === type.code);
		return commodityType.min;
	}

	get targetWeightMax() {
		const _commodity = this.commodity;
		const commodityType = _commodity.commodityTypes.find((type) => this._commodityTypeCode === type.code);
		return commodityType.max;
	}

	@action
	async getStates() {
		let query = States;
		this.statesList = await this.apollo.watchQuery({ query });
	}

	@action
	resetForm() {
		this.name = null;
		this.email = null;
		this.statesList = [];
		this.stateCode = this.statesList.States?.map((state) => state.rmaStateCode).shift();
		this.headCount = 50;
		this.targetWeightCWT = 5.0;
	}

	@action
	async submit(event) {
		event.preventDefault();

		const createAnonymousNotificationSetting = {
			type: 'Email',
			email: this.email,
		};
		const createAnonymousNotificationSubscription = {
			type: 'Lrp',
			name: this.name,
			commodityCode: this.commodityCode,
			commodityTypeCode: this.commodityTypeCode,
			headCount: +this.headCount,
			targetWeightCwt: +this.targetWeightCWT,
			stateCode: this.stateCode,
		};

		try {
			await this.apollo.mutate({
				mutation,
				variables: {
					createAnonymousNotificationSetting,
					createAnonymousNotificationSubscription,
				},
			});

			this.hasError = false;
			this.router.transitionTo('notifications.subscribe-confirmation', 'product=Lrp');
			mixpanelCustomTracking('Submit Event', {
				Event: 'LRP Create Subscription Submitted',
			});
			this.resetForm();
		} catch (err) {
			this.hasError = true;
			console.error(err);
		}
	}
}
