import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import States from 'insurance-quoter/graphql/queries/states.graphql';
import mutation from 'insurance-quoter/graphql/mutations/update-subscription.graphql';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';

export default class NotificationsDrpModifySubscriptionController extends Controller {
	@queryManager apollo;
	@service router;

	queryParams = ['notificationId'];

	@tracked notificationId;
	@tracked _name = this.model?.findAnonymousNotification.Subscription.name;
	@tracked _email = this.model?.findAnonymousNotification.Setting.email;
	@tracked _butterfat = this.model?.findAnonymousNotification.Subscription.butterfat;
	@tracked _protein = this.model?.findAnonymousNotification.Subscription.protein;
	@tracked statesList = [];
	@tracked rmaStateCode = '';
	@tracked stateCode = this.model?.findAnonymousNotification.Subscription.stateCode;
	@tracked hasError = false;

	get name() {
		return this._name;
	}

	set name(name) {
		this._name = name;
	}

	get email() {
		return this._email;
	}

	set email(email) {
		this._email = email;
	}

	get butterfat() {
		return this._butterfat;
	}

	set butterfat(butterfat) {
		this._butterfat = butterfat;
	}

	get protein() {
		return this._protein;
	}

	set protein(protein) {
		this._protein = protein;
	}

	@action
	async getStates() {
		let query = States;
		this.statesList = await this.apollo.watchQuery({ query });
	}

	@action
	updateCurrentState(state) {
		const currentFullState = States.filter((e) => e.id === state);
		this.updateState(currentFullState);
	}

	@action
	async submit(event) {
		event.preventDefault();

		const id = this.notificationId;
		const createAnonymousNotificationSetting = {
			type: 'Email',
			email: this._email,
		};
		const createAnonymousNotificationSubscription = {
			type: 'InsuranceQuote',
			name: this._name,
			butterfat: +this._butterfat,
			protein: +this._protein,
			stateCode: this.stateCode,
		};
		try {
			await this.apollo.mutate({
				mutation,
				variables: {
					id,
					createAnonymousNotificationSetting,
					createAnonymousNotificationSubscription,
				},
			});
			this.hasError = false;
			this.router.transitionTo('notifications.modify-confirmation', 'product=Drp');
		} catch (err) {
			this.hasError = true;
			console.error(err);
		}
	}
}
