import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import States from 'insurance-quoter/graphql/queries/states.graphql';
import mutation from 'insurance-quoter/graphql/mutations/update-subscription.graphql';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';

export default class NotificationsLrpModifySubscriptionController extends Controller {
	@queryManager apollo;
	@service router;

	queryParams = ['notificationId'];
	commodities = [
		{
			name: 'Feeder Cattle',
			code: '0801',
			commodityTypes: [
				{
					name: 'Steers Weight (< 600 LBS)',
					code: '809',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Steers Weight (600 - 1000 LBS)',
					code: '810',
					min: 6,
					max: 10,
				},
				{
					name: 'Heifers Weight (< 600 LBS)',
					code: '811',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Heifers Weight (600 - 1000 LBS)',
					code: '812',
					min: 6,
					max: 10,
				},
				{
					name: 'Brahman Weight (< 600 LBS)',
					code: '813',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Brahman Weight (600 - 1000 LBS)',
					code: '814',
					min: 6,
					max: 10,
				},
				{
					name: 'Dairy Weight (< 600 LBS)',
					code: '815',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Dairy Weight (600 - 1000 LBS)',
					code: '816',
					min: 6,
					max: 10,
				},
				{
					name: 'Unborn Steers & Heifers',
					code: '817',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Unborn Brahman',
					code: '818',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Unborn Dairy',
					code: '819',
					min: 1,
					max: 5.99,
				},
			],
		},
		{
			name: 'Fed Cattle',
			code: '0802',
			commodityTypes: [
				{
					name: 'Steers & Heifers  (1,000 - 1,600 LBS)',
					code: '820',
					min: 10,
					max: 16,
				},
			],
		},
		{
			name: 'Swine',
			code: '0815',
			commodityTypes: [
				{
					name: 'Unborn Swine',
					code: '821',
					min: 1.5,
					max: 2.25,
				},
				{
					name: 'No Type Specified',
					code: '997',
					min: 1.5,
					max: 2.25,
				},
			],
		},
	];

	notificationId;
	product = 'Lrp';
	@tracked _name = this.model?.findAnonymousNotification.Subscription.name;
	@tracked _email = this.model?.findAnonymousNotification.Setting.email;
	@tracked statesList = [];
	@tracked rmaStateCode = '';
	@tracked stateCode = this.model?.findAnonymousNotification.Subscription.stateCode;
	@tracked _headCount = this.model?.findAnonymousNotification.Subscription.headCount;
	@tracked _targetWeightCWT = this.model?.findAnonymousNotification.Subscription.targetWeightCwt;
	@tracked _commodityCode = this.model?.findAnonymousNotification.Subscription.commodityCode;
	@tracked _commodityTypeCode = this.model?.findAnonymousNotification.Subscription.commodityTypeCode;
	@tracked hasError = false;

	get name() {
		return this._name;
	}

	set name(name) {
		this._name = name;
	}

	get email() {
		return this._email;
	}

	set email(email) {
		this._email = email;
	}

	get commodityCode() {
		return this._commodityCode;
	}

	set commodityCode(code) {
		this._commodityCode = code;
		this._commodityTypeCode = this.commodities.find((commodity) => commodity.code == code)?.commodityTypes[0].code;
		this.targetWeightCWT = this.commodities.find((commodity) => commodity.code == code)?.commodityTypes[0].min;
	}

	get commodityTypeCode() {
		return this._commodityTypeCode;
	}

	set commodityTypeCode(code) {
		this._commodityTypeCode = code;
		this.targetWeightCWT = this.targetWeightMin;
	}

	get commodity() {
		return this.commodities.find((commodity) => commodity.code == this._commodityCode);
	}

	get state() {
		return States.find((state) => {
			return state.rmaStateCode == this.rmaStateCode;
		});
	}

	get targetWeightMin() {
		const _commodity = this.commodity;
		if (_commodity) {
			const commodityType = _commodity.commodityTypes.find((type) => this._commodityTypeCode === type.code);
			return commodityType.min;
		} else {
			return null;
		}
	}

	get targetWeightMax() {
		const _commodity = this.commodity;
		if (_commodity) {
			const commodityType = _commodity.commodityTypes.find((type) => this._commodityTypeCode === type.code);
			return commodityType.max;
		} else {
			return null;
		}
	}

	get headCount() {
		return this._headCount;
	}

	set headCount(count) {
		this._headCount = count;
	}

	get targetWeightCWT() {
		return this._targetWeightCWT;
	}

	set targetWeightCWT(weight) {
		this._targetWeightCWT = weight;
	}

	@action
	async getStates() {
		let query = States;
		this.statesList = await this.apollo.watchQuery({ query });
	}

	@action
	updateCurrentState(state) {
		const currentFullState = States.filter((e) => e.id === state);
		this.updateState(currentFullState);
	}

	@action
	async submit(event) {
		event.preventDefault();

		const id = this.notificationId;
		const createAnonymousNotificationSetting = {
			type: 'Email',
			email: this._email,
		};
		const createAnonymousNotificationSubscription = {
			type: 'Lrp',
			name: this._name,
			commodityCode: this._commodityCode,
			commodityTypeCode: this._commodityTypeCode,
			headCount: +this._headCount,
			targetWeightCwt: +this._targetWeightCWT,
			stateCode: this.stateCode,
		};

		try {
			await this.apollo.mutate({
				mutation,
				variables: {
					id,
					createAnonymousNotificationSetting,
					createAnonymousNotificationSubscription,
				},
			});
			this.hasError = false;
			this.router.transitionTo('notifications.modify-confirmation', 'product=Lrp');
		} catch (err) {
			this.hasError = true;
			console.error(err);
		}
	}
}
