import { tracked } from '@glimmer/tracking';

export default class DrpPractice {
	@tracked componentPriceWeightingFactorRestrictedValue;
	@tracked classPriceWeightingFactorRestrictedValue;
	@tracked expectedButterfatPrice;
	@tracked expectedClassIIIPrice;
	@tracked expectedClassIVPrice;
	@tracked expectedOtherSolidsPrice;
	@tracked expectedNonfatSolidsPrice;
	@tracked expectedProteinPrice;
	@tracked expectedYield;
	@tracked expectedYieldStandardDeviation;
	@tracked name;
	@tracked practiceAbbreviation;
	@tracked practiceCode;
	@tracked quarter;
	@tracked reinsuranceYear;
	@tracked year;

	constructor(practice) {
		this.componentPriceWeightingFactorRestrictedValue = practice.componentPriceWeightingFactorRestrictedValue;
		this.classPriceWeightingFactorRestrictedValue = practice.classPriceWeightingFactorRestrictedValue;
		this.expectedButterfatPrice = practice.expectedButterfatPrice;
		this.expectedClassIIIPrice = practice.expectedClassIIIPrice;
		this.expectedClassIVPrice = practice.expectedClassIVPrice;
		this.expectedOtherSolidsPrice = practice.expectedOtherSolidsPrice;
		this.expectedNonfatSolidsPrice = practice.expectedNonfatSolidsPrice;
		this.expectedProteinPrice = practice.expectedProteinPrice;
		this.expectedYield = practice.expectedYield;
		this.expectedYieldStandardDeviation = practice.expectedYieldStandardDeviation;
		this.name = practice.name;
		this.practiceAbbreviation = practice.practiceAbbreviation;
		this.practiceCode = practice.practiceCode;
		this.quarter = practice.quarter;
		this.reinsuranceYear = practice.reinsuranceYear;
		this.year = practice.year;
	}

	get showComponentClassIIIOnly() {
		if (
			this.isClassIIIComponentAvailable === true &&
			this.isClassIVComponentAvailable === false &&
			this.isBothClassComponentsAvailable === false
		) {
			return true;
		} else {
			return false;
		}
	}

	get showComponentClassIVOnly() {
		if (
			this.isClassIVComponentAvailable === true &&
			this.isClassIIIComponentAvailable === false &&
			this.isBothClassComponentsAvailable === false
		) {
			return true;
		} else {
			return false;
		}
	}

	get isClassIIIComponentAvailable() {
		if (
			this.expectedButterfatPrice &&
			this.expectedProteinPrice &&
			this.expectedOtherSolidsPrice &&
			this.componentPriceWeightingFactorRestrictedValue !== 0
		) {
			return true;
		} else {
			return false;
		}
	}

	get isClassIVComponentAvailable() {
		if (this.expectedButterfatPrice && this.expectedNonfatSolidsPrice && this.componentPriceWeightingFactorRestrictedValue !== 1) {
			return true;
		} else {
			return false;
		}
	}

	get showClassIIIOnly() {
		if (this.isClassIIIAvailable === true && this.isClassIVAvailable === false && this.isBothClassAvailable === false) {
			return true;
		} else {
			return false;
		}
	}

	get showClassIVOnly() {
		if (this.isClassIVAvailable === true && this.isClassIIIAvailable === false && this.isBothClassAvailable === false) {
			return true;
		} else {
			return false;
		}
	}

	get isClassIIIAvailable() {
		return this.classPriceWeightingFactorRestrictedValue !== 0 ? true : false;
	}

	get isClassIVAvailable() {
		return this.classPriceWeightingFactorRestrictedValue !== 1 ? true : false;
	}

	get isBothClassAvailable() {
		return this.isClassIIIAvailable && this.isClassIVAvailable ? true : false;
	}

	get isBothClassComponentsAvailable() {
		if (this.isClassIIIComponentAvailable && this.isClassIVComponentAvailable) {
			return true;
		} else {
			return false;
		}
	}

	get missingComponent() {
		if (this.isBothClassComponentsAvailable || this.isClassIIIComponentAvailable || this.isClassIVComponentAvailable) {
			return false;
		} else {
			return true;
		}
	}

	get missingClass() {
		if (this.isBothClassAvailable || this.isClassIIIAvailable || this.isClassIVAvailable) {
			return false;
		} else {
			return true;
		}
	}

	get componentRestrictions() {
		if (this.showComponentClassIIIOnly) {
			return '1';
		} else if (this.showComponentClassIVOnly) {
			return '0';
		} else {
			return null;
		}
	}

	get classRestrictions() {
		if (this.showClassIIIOnly) {
			return '1';
		} else if (this.showClassIVOnly) {
			return '0';
		} else {
			return null;
		}
	}

	get mixDisabled() {
		return this.missingComponent || this.missingClass;
	}
}
