import Controller from '@ember/controller';
export default class NotificationsModifyConfirmationController extends Controller {
	get quoterRoute() {
		return this.target.url === '/notifications/modify-confirmation/product=Lrp'
			? 'lrp-quotes'
			: this.target.url === '/notifications/modify-confirmation/product=Drp'
				? 'drp-quotes'
				: null;
	}
}
