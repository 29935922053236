export default class LrpEndDate {
	constructor(coverage) {
		this.coverage = coverage;
		this.endDate = coverage.FormattedendDate;
		this.expectedValue = coverage.expectedValue;
		this.insuredSharePercentage = coverage.insuredSharePercentage;
		this.coveragePrice = coverage.coveragePrice;
		this.rate = coverage.rate;
	}
}
