import Route from '@ember/routing/route';
import query from 'insurance-quoter/graphql/queries/notification-by-id.graphql';
import { queryManager } from 'ember-apollo-client';
import { mixpanelPageView } from 'insurance-quoter/utils/mixpanel-tracking';
export default class NotificationsUnsubscribeRoute extends Route {
	@queryManager apollo;

	queryParams = {
		notificationId: { refreshModel: true },
	};

	async model(params) {
		const variables = {
			id: params.notificationId,
		};
		mixpanelPageView(document.referrer);
		return this.apollo.watchQuery({ query, variables });
	}
}
