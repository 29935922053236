import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n\t<Input\n\t\t@type='range'\n\t\tid={{@id}}\n\t\tdisabled={{@isDisabled}}\n\t\tmin={{@min}}\n\t\tmax={{@max}}\n\t\tstep={{@step}}\n\t\t@value={{@value}}\n\t\t{{on 'change' this.rangeChanged}}\n\t\t{{on 'input' this.rangeChanged}}\n\t\t{{on 'mouseup' this.mouseUp}}\n\t\t{{on 'touchend' this.mouseUp}}\n\t\t...attributes\n\t/>\n</div>", {"contents":"<div>\n\t<Input\n\t\t@type='range'\n\t\tid={{@id}}\n\t\tdisabled={{@isDisabled}}\n\t\tmin={{@min}}\n\t\tmax={{@max}}\n\t\tstep={{@step}}\n\t\t@value={{@value}}\n\t\t{{on 'change' this.rangeChanged}}\n\t\t{{on 'input' this.rangeChanged}}\n\t\t{{on 'mouseup' this.mouseUp}}\n\t\t{{on 'touchend' this.mouseUp}}\n\t\t...attributes\n\t/>\n</div>","moduleName":"insurance-quoter/components/input-range.hbs","parseOptions":{"srcName":"insurance-quoter/components/input-range.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class InputRangeComponent extends Component {
	@action
	rangeChanged(e) {
		let targetValue = +e.target.value;
		this.args.onChange(targetValue);
	}

	@action
	mouseUp(e) {
		let targetValue = +e.target.value;
		this.args.rangeMouseUp(targetValue);
	}
}
