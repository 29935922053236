export default [
	{ id: '101', name: 'Steers Weight 1' },
	{ id: '102', name: 'Steers Weight 2' },
	{ id: '103', name: 'Heifers Weight 1' },
	{ id: '104', name: 'Heifers Weight 2' },
	{ id: '105', name: 'Brahman Weight 1' },
	{ id: '106', name: 'Brahman Weight 2' },
	{ id: '107', name: 'Dairy Weight 1' },
	{ id: '108', name: 'Dairy Weight 2' },
	{ id: '109', name: 'Unborn Steers & Heifers' },
	{ id: '110', name: 'Unborn Brahman' },
	{ id: '111', name: 'Unborn Dairy' },
];
