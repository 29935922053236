import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'insurance-quoter/config/environment';
import 'ember-basic-dropdown/styles';
import mixpanel from 'mixpanel-browser';
import ENV from 'insurance-quoter/config/environment';

if (ENV.environment === 'production') {
	mixpanel.init(ENV.mixpanelToken, {
		debug: false,
	});
}

export default class App extends Application {
	modulePrefix = config.modulePrefix;
	podModulePrefix = config.podModulePrefix;
	Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
