import Service from '@ember/service';
import { later, debounce, next } from '@ember/runloop';
import { TrackedMap } from 'tracked-maps-and-sets';
import { tracked } from '@glimmer/tracking';

export default class MarketDataService extends Service {
	subscriptions = new TrackedMap();
	pollFrequency = 5000;
	@tracked scheduledPoll = null;
	@tracked marketDataUpdated = true;

	constructor() {
		super(...arguments);

		// Initial poll should be immediately after this has been constructed.
		later(this, this.poll.bind(this), 1);
	}

	register(symbol) {
		next(() => {
			if (this.subscriptions.has(symbol)) {
				const previousQuantity = this.subscriptions.get(symbol).get('quantity');
				this.subscriptions.get(symbol).set('quantity', previousQuantity + 1);
			} else {
				const newMap = new TrackedMap();
				newMap.set('quantity', 1);
				newMap.set('price', null);

				this.subscriptions.set(symbol, newMap);

				debounce(this, this.updateMarketData, 100);
			}
		});
	}

	unregister(symbol) {
		if (this.subscriptions.has(symbol)) {
			const previousQuantity = this.subscriptions.get(symbol).get('quantity');
			this.subscriptions.get(symbol).set('quantity', previousQuantity - 1);
		} else {
			throw Error('Unregistering a non-existent subscription.');
		}
	}

	getMarketDatum(symbol) {
		if (this.subscriptions.has(symbol)) {
			return this.subscriptions.get(symbol).get('price');
		}
	}

	async updateMarketData() {
		const subscriptionEntries = [...this.subscriptions.entries()];
		const activeSubscriptions = subscriptionEntries.filter((entry) => {
			return entry[1].get('quantity') > 0;
		});

		const symbols = activeSubscriptions.map((subscription) => {
			return subscription[0];
		});

		if (symbols.length > 0) {
			let length = symbols.length;

			let current = 0;
			let groups = [];
			let end;

			while (current < length) {
				end = length < current + 50 ? length : current + 50;
				groups.push(symbols.slice(current, end));
				current += 50;
			}

			let results = [];

			for (let i = 0; i < groups.length; i++) {
				let symbols = groups[i];
				let res = await fetch(
					`https://ondemand.websol.barchart.com/getQuote.json?apikey=e1e7b7d8187322d75e97b1c91fa2839d&symbols=${symbols.join(
						',',
					)}&fields=settlement,previousSettlement,openInterest`,
					{
						method: 'GET',
						headers: {},
					},
				);
				results.push(res);
			}

			let prices = [];

			for (let i = 0; i < results.length; i++) {
				let result = results[i];
				let r = (await result.json()).results;
				prices.push(...r);
			}

			prices.forEach((price) => {
				if (this.subscriptions.has(price.symbol)) {
					this.subscriptions.get(price.symbol).set('price', price);
				}
			});

			this.marketDataUpdated = true;
		}
	}

	async poll() {
		await this.updateMarketData();
		this.scheduledPoll = later(this, this.poll.bind(this), this.pollFrequency);
	}
}
