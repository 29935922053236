import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='relative' ...attributes>\n\t<label\n\t\tclass='{{if @screenReaderOnly \" sr-only\" \"\"}} block mb-1 whitespace-nowrap caption-m-heavy text-brand-gray-90'\n\t\tfor={{@id}}\n\t>{{@label}}</label>\n\t<select\n\t\tclass='text-brand-gray-90 placeholder-brand-gray-50 border border-brand-gray-50 rounded-md shadow-sm hover:border-brand-interactive-blue-70 hover:bg-brand-gray-10 focus:outline-none focus:border-1 focus:border-brand-interactive-blue-70 transition duration-150 ease-in-out max-w-2xs\n\t\t\t{{@customWidth}}'\n\t\tid={{@id}}\n\t\tname={{@id}}\n\t\t{{on 'change' this.selectChanged}}\n\t>\n\t\t{{yield}}\n\t</select>\n</div>", {"contents":"<div class='relative' ...attributes>\n\t<label\n\t\tclass='{{if @screenReaderOnly \" sr-only\" \"\"}} block mb-1 whitespace-nowrap caption-m-heavy text-brand-gray-90'\n\t\tfor={{@id}}\n\t>{{@label}}</label>\n\t<select\n\t\tclass='text-brand-gray-90 placeholder-brand-gray-50 border border-brand-gray-50 rounded-md shadow-sm hover:border-brand-interactive-blue-70 hover:bg-brand-gray-10 focus:outline-none focus:border-1 focus:border-brand-interactive-blue-70 transition duration-150 ease-in-out max-w-2xs\n\t\t\t{{@customWidth}}'\n\t\tid={{@id}}\n\t\tname={{@id}}\n\t\t{{on 'change' this.selectChanged}}\n\t>\n\t\t{{yield}}\n\t</select>\n</div>","moduleName":"insurance-quoter/components/input-select.hbs","parseOptions":{"srcName":"insurance-quoter/components/input-select.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class InputSelectComponent extends Component {
	@action
	selectChanged(e) {
		let el = e.target;
		let v = el.options[el.selectedIndex].value;

		this.args.onUpdate(v);
	}
}
