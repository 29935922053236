import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import mutation from 'insurance-quoter/graphql/mutations/unsubscribe.graphql';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import { mixpanelCustomTracking } from 'insurance-quoter/utils/mixpanel-tracking';
export default class NotificationsUnsubscribeController extends Controller {
	@queryManager apollo;
	@service router;

	queryParams = ['notificationId'];

	@tracked notificationId;

	get quoterRoute() {
		return this.model.findAnonymousNotification.Subscription.type === 'Lrp'
			? 'lrp-quotes'
			: this.model.findAnonymousNotification.Subscription.type === 'InsuranceQuote'
				? 'drp-quotes'
				: null;
	}

	@action
	async unsubscribe() {
		const id = this.notificationId;

		try {
			await this.apollo.mutate({
				mutation,
				variables: {
					id,
				},
			});
			mixpanelCustomTracking('Submit Event', {
				Event: `Unsubscribe Submitted - ${this.model.findAnonymousNotification.Subscription.type}`,
			});
		} catch (err) {
			console.error(err);
		}

		this.router.transitionTo(this.quoterRoute);
	}
}
