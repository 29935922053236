import Route from '@ember/routing/route';
import query from 'insurance-quoter/graphql/queries/notification-by-id.graphql';
import { queryManager } from 'ember-apollo-client';

export default class NotificationsDrpModifySubscriptionRoute extends Route {
	@queryManager apollo;

	queryParams = {
		notificationId: { refreshModel: true },
	};

	async model(params) {
		const variables = {
			id: params.notificationId,
		};

		return this.apollo.watchQuery({ query, variables });
	}
}
