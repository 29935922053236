import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import { DateTime } from 'luxon';
import { mixpanelCustomTracking } from 'insurance-quoter/utils/mixpanel-tracking';
class LrpPremium {
	constructor(coverageLevel, numberOfHead, targetWeight, startWeight, animalCostPerPound, costOfGain) {
		this.coveragePrice = coverageLevel.coveragePrice;
		this.endDate = coverageLevel.endDate;
		this.expectedEndingValueAmount = coverageLevel.expectedEndingValueAmount;

		this.insuredValue = numberOfHead * targetWeight * coverageLevel.coveragePrice;
		this.rate = coverageLevel.livestockRate;
		this.totalPremium = Math.round(this.insuredValue * this.rate);
		this.targetWeight = targetWeight;
		this.startWeight = startWeight;
		this.animalCostPerPound = animalCostPerPound;
		this.costOfGain = costOfGain;

		let subsidyRate;

		let coverageLevelPercent = coverageLevel.livestockCoverageLevelPercent;
		if (coverageLevelPercent >= 0.95) {
			subsidyRate = 0.35;
		} else if (coverageLevelPercent >= 0.9) {
			subsidyRate = 0.4;
		} else if (coverageLevelPercent >= 0.85) {
			subsidyRate = 0.45;
		} else if (coverageLevelPercent >= 0.8) {
			subsidyRate = 0.5;
		} else if (coverageLevelPercent >= 0.7) {
			subsidyRate = 0.55;
		}

		this.livestockRate = coverageLevel.livestockRate;
		this.subsidy = Math.round(this.totalPremium * subsidyRate);

		this.producerPremium = this.totalPremium - this.subsidy;
		this.producerPremiumPerHead = this.producerPremium / numberOfHead;
		this.producerPremiumPerCwt = this.producerPremium / numberOfHead / targetWeight;
	}

	get projectedPL() {
		// If Animal Cost, Cost of Gain, Start Weight are all 0, projectedPL should display '-' in the table cell

		const costOfCalf = +this.costOfGain * (+this.targetWeight * 100 - +this.startWeight) + +this.startWeight * +this.animalCostPerPound;

		const gainPrice = (+this.coveragePrice - this.producerPremiumPerCwt) * +this.targetWeight;

		return +this.animalCostPerPound === 0 && +this.costOfGain === 0 && +this.startWeight === 0 ? null : gainPrice - costOfCalf;
	}
}

export default class LrpQuotesController extends Controller {
	@queryManager apollo;

	queryParams = [
		'_commodityCode',
		'_commodityTypeCode',
		'marketDate',
		'startWeight',
		'_headCount',
		'_targetWeightCWT',
		'_stateCode',
		'showAllCoverageLevels',
		'animalCostPerPound',
		'costOfGain',
	];

	commodities = [
		{
			name: 'Feeder Cattle',
			code: '0801',
			commodityTypes: [
				{
					name: 'Steers Weight (< 600 LBS)',
					code: '809',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Steers Weight (600 - 1000 LBS)',
					code: '810',
					min: 6,
					max: 10,
				},
				{
					name: 'Heifers Weight (< 600 LBS)',
					code: '811',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Heifers Weight (600 - 1000 LBS)',
					code: '812',
					min: 6,
					max: 10,
				},
				{
					name: 'Brahman Weight (< 600 LBS)',
					code: '813',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Brahman Weight (600 - 1000 LBS)',
					code: '814',
					min: 6,
					max: 10,
				},
				{
					name: 'Dairy Weight (< 600 LBS)',
					code: '815',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Dairy Weight (600 - 1000 LBS)',
					code: '816',
					min: 6,
					max: 10,
				},
				{
					name: 'Unborn Steers & Heifers',
					code: '817',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Unborn Brahman',
					code: '818',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Unborn Dairy',
					code: '819',
					min: 1,
					max: 5.99,
				},
			],
		},
		{
			name: 'Fed Cattle',
			code: '0802',
			commodityTypes: [
				{
					name: 'Steers & Heifers  (1,000 - 1,600 LBS)',
					code: '820',
					min: 10,
					max: 16,
				},
			],
		},
		{
			name: 'Swine',
			code: '0815',
			commodityTypes: [
				{
					name: 'Unborn Swine',
					code: '821',
					min: 1.5,
					max: 2.25,
				},
				{
					name: 'No Type Specified',
					code: '997',
					min: 1.5,
					max: 2.25,
				},
			],
		},
	];

	@tracked _stateCode = '26';
	@tracked marketDate = DateTime.now().plus({ months: 3 }).toISODate();
	@tracked _headCount = 500;
	@tracked _targetWeightCWT = 5.0;
	@tracked invalidHeadCountMessage = null;
	@tracked beforeMarketCoverage = null;
	@tracked showAllCoverageLevels = false;
	@tracked currentlyLoading = false;
	@tracked animalCostPerPound = 0;
	@tracked startWeight = 0;
	@tracked costOfGain = 0;

	@tracked _commodityCode = this.commodities[0].code;
	@tracked _commodityTypeCode = this.commodities[0].commodityTypes[0].code;

	get state() {
		return this.model.States.find((state) => {
			return state.rmaStateCode == this._stateCode;
		});
	}

	get stateCode() {
		return this._stateCode;
	}

	set stateCode(code) {
		this._stateCode = code;
		mixpanelCustomTracking('LRP - Location Changed', {
			event: `Location changed to ${this.state.name}`,
		});
	}

	get commodityCode() {
		return this._commodityCode;
	}

	set commodityCode(code) {
		mixpanelCustomTracking('LRP - Commodity changed', {
			event: `Commodity changed to code ${code}`,
		});
		this._commodityCode = code;
		this._commodityTypeCode = this.commodities.find((commodity) => commodity.code == code)?.commodityTypes[0].code;
		this.targetWeightCWT = this.commodities.find((commodity) => commodity.code == code)?.commodityTypes[0].min;
	}

	get commodityTypeCode() {
		return this._commodityTypeCode;
	}

	set commodityTypeCode(code) {
		mixpanelCustomTracking('LRP - Commodity Type changed', {
			event: `Commodity Type changed to code ${code}`,
		});
		this._commodityTypeCode = code;
		this.targetWeightCWT = this.targetWeightMin;
	}

	get headCount() {
		return this._headCount;
	}

	set headCount(count) {
		mixpanelCustomTracking('LRP - Animal Head Count Changed', {
			event: `Animal Head Count changed to ${count}`,
		});
		this._headCount = count;
	}

	get targetWeightMin() {
		const _commodity = this.commodity;
		const commodityType = _commodity.commodityTypes.find((type) => this._commodityTypeCode === type.code);
		return commodityType.min;
	}

	get targetWeightMax() {
		const _commodity = this.commodity;
		const commodityType = _commodity.commodityTypes.find((type) => this._commodityTypeCode === type.code);
		return commodityType.max;
	}

	get targetWeightCWT() {
		return this._targetWeightCWT;
	}

	set targetWeightCWT(weight) {
		mixpanelCustomTracking('LRP - Target Weight CWT', {
			event: `Target Weight CWT changed to ${weight}`,
		});
		this._targetWeightCWT = weight;
	}

	get commodity() {
		return this.commodities.find((commodity) => commodity.code == this.commodityCode);
	}

	get coverageLevelClasses() {
		return this.model.MostRecentLrpDailyPrices.map((coverageLevel) => {
			return new LrpPremium(
				coverageLevel,
				this.headCount,
				this.targetWeightCWT,
				this.startWeight,
				this.animalCostPerPound,
				this.costOfGain,
			);
		});
	}

	get highestCoverageLevels() {
		const previousValues = {};

		const sortedCoverageLevels = this.coverageLevelClasses
			.sort((a, b) => b.coveragePrice - a.coveragePrice)
			.sort((a, b) => a.endDate.localeCompare(b.endDate));

		return sortedCoverageLevels.filter((level) => {
			if (previousValues[level.endDate]) {
				return false;
			} else {
				previousValues[level.endDate] = true;
				return true;
			}
		});
	}

	get salesEffectiveDate() {
		return this.model.MostRecentLrpDailyPrices[0]?.salesEffectiveDate;
	}

	get allCoverageLevels() {
		return this.coverageLevelClasses.sort((a, b) => a.coveragePrice - b.coveragePrice).sort((a, b) => a.endDate.localeCompare(b.endDate));
	}

	get coverageLevels() {
		if (this.showAllCoverageLevels) {
			return this.allCoverageLevels;
		} else {
			return this.highestCoverageLevels;
		}
	}
}
