import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import States from 'insurance-quoter/graphql/queries/states.graphql';
import { queryManager } from 'ember-apollo-client';
import mutation from 'insurance-quoter/graphql/mutations/create-subscription.graphql';
import { inject as service } from '@ember/service';
import { mixpanelCustomTracking } from 'insurance-quoter/utils/mixpanel-tracking';

export default class NotificationsDrpCreateSubscriptionController extends Controller {
	@queryManager apollo;
	@service router;

	@tracked name = null;
	@tracked email = null;
	@tracked statesList = [];
	@tracked stateCode = this.statesList.States?.map((state) => state.rmaStateCode).shift();
	@tracked butterfat = 3.5;
	@tracked protein = 3;
	@tracked hasError = false;

	@action
	async getStates() {
		let query = States;
		this.statesList = await this.apollo.watchQuery({ query });
	}

	@action
	resetForm() {
		this.name = null;
		this.email = null;
		this.statesList = [];
		this.stateCode = this.statesList.States?.map((state) => state.rmaStateCode).shift();
		this.butterfat = 3.5;
		this.protein = 3;
	}

	@action
	async submit(event) {
		event.preventDefault();

		const createAnonymousNotificationSetting = {
			type: 'Email',
			email: this.email,
		};
		const createAnonymousNotificationSubscription = {
			type: 'InsuranceQuote',
			name: this.name,
			stateCode: this.stateCode,
			butterfat: +this.butterfat,
			protein: +this.protein,
		};

		try {
			await this.apollo.mutate({
				mutation,
				variables: {
					createAnonymousNotificationSetting,
					createAnonymousNotificationSubscription,
				},
			});

			this.hasError = false;
			this.router.transitionTo('notifications.subscribe-confirmation', 'product=Drp');
			mixpanelCustomTracking('Submit Event', {
				Event: 'DRP Create Subscription Submitted',
			});
			this.resetForm();
		} catch (err) {
			this.hasError = true;
			console.error(err);
		}
	}
}
