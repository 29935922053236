import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"insurance-quoter/components/intl-number-format.hbs","parseOptions":{"srcName":"insurance-quoter/components/intl-number-format.hbs"}});
import Component from '@glimmer/component';

export default class IntlNumberFormatComponent extends Component {
	constructor(owner, args) {
		super(owner, args);
	}

	get formattedValue() {
		let options = {};

		Object.assign(
			options,
			this.args.style && {
				style: this.args.style,
			},
			this.args.localeMatcher && {
				localeMatcher: this.args.localeMatcher,
			},
			this.args.currency && {
				currency: this.args.currency,
			},
			this.args.currencyDisplay && {
				currencyDisplay: this.args.currencyDisplay,
			},
			this.args.useGrouping && {
				useGrouping: this.args.useGrouping,
			},
			this.args.minimumIntegerDigits && {
				minimumIntegerDigits: this.args.minimumIntegerDigits,
			},
			this.args.minimumFractionDigits && {
				minimumFractionDigits: this.args.minimumFractionDigits,
			},
			this.args.maximumFractionDigits && {
				maximumFractionDigits: this.args.maximumFractionDigits,
			},
			this.args.minimumSignificantDigits && {
				minimumSignificantDigits: this.args.minimumSignificantDigits,
			},
			this.args.maximumSignificantDigits && {
				maximumSignificantDigits: this.args.maximumSignificantDigits,
			},
			this.args.currencySign && {
				currencySign: this.args.currencySign,
			},
		);

		if (this.args.locales) {
			return new Intl.NumberFormat(this.args.locales, options).format(this.args.value);
		} else if (this.args.value) {
			return new Intl.NumberFormat(undefined, options).format(this.args.value);
		} else {
			return '-';
		}
	}
}
