import { tracked } from '@glimmer/tracking';

export default class Quote {
	@tracked coverageLevel;
	@tracked declaredProduction;
	@tracked hasClassQuote;
	@tracked hasComponentQuote;
	@tracked practiceCode;
	@tracked protectionFactor;
	@tracked salesEffectiveDate;
	@tracked percentageComponentInsurance;
	@tracked currentPractice;
	@tracked class;
	@tracked component;

	constructor(quote, percentageComponentInsurance, currentPractice) {
		(this.coverageLevel = quote.coverageLevel),
			(this.declaredProduction = quote.declaredProduction),
			(this.hasClassQuote = quote.hasClassQuote),
			(this.hasComponentQuote = quote.hasComponentQuote),
			(this.practiceCode = quote.practiceCode),
			(this.protectionFactor = quote.protectionFactor),
			(this.salesEffectiveDate = quote.salesEffectiveDate),
			(this.stateAbbreviation = quote.stateAbbreviation),
			(this.class = quote.ClassEndorsement),
			(this.component = quote.ComponentEndorsement),
			(this.percentageComponentInsurance = percentageComponentInsurance),
			(this.currentPractice = currentPractice);
	}

	get percentageClassInsurance() {
		return 100 - this.percentageComponentInsurance;
	}

	get classBasedPounds() {
		return (this.percentageClassInsurance / 100) * this.declaredProduction;
	}

	get componentBasedPounds() {
		return (this.percentageComponentInsurance / 100) * this.declaredProduction;
	}

	get weightedClassRevenueGuarantee() {
		const revenueGuarantee = this.class ? this.class.revenueGuarantee : 0;

		return revenueGuarantee * (1 - this.percentageComponentInsurance / 100);
	}

	get weightedComponentRevenueGuarantee() {
		if (this.component) {
			return this.component.revenueGuarantee * (this.percentageComponentInsurance / 100);
		} else {
			return 0;
		}
	}

	get combinedRevenueGuarantee() {
		if (this.currentPractice && this.currentPractice.missingClass) {
			return this.component.revenueGuarantee;
		}

		if (this.currentPractice && this.currentPractice.missingComponent) {
			return this.class.revenueGuarantee;
		}

		return this.weightedClassRevenueGuarantee + this.weightedComponentRevenueGuarantee;
	}

	get classTriggerPriceCWT() {
		return (this.weightedClassRevenueGuarantee / this.classBasedPounds) * 100;
	}

	get componentTriggerPriceCWT() {
		return (this.weightedComponentRevenueGuarantee / this.componentBasedPounds) * 100;
	}

	get combinedTriggerPriceCWT() {
		return (this.combinedRevenueGuarantee / this.declaredProduction) * 100;
	}

	get weightedClassLiability() {
		const liability = this.class ? this.class.liability : 0;
		return liability * (1 - this.percentageComponentInsurance / 100);
	}

	get weightedComponentLiability() {
		const liability = this.component ? this.component.liability : 0;

		return liability * (this.percentageComponentInsurance / 100);
	}

	get combinedLiability() {
		if (this.currentPractice && this.currentPractice.missingClass) {
			return this.component.liability;
		}

		if (this.currentPractice && this.currentPractice.missingComponent) {
			return this.class.liability;
		}

		return this.weightedClassLiability + this.weightedComponentLiability;
	}

	get weightedClassLiabilityCWT() {
		return (this.weightedClassLiability / this.classBasedPounds) * 100;
	}

	get weightedComponentLiabilityCWT() {
		return (this.weightedComponentLiability / this.componentBasedPounds) * 100;
	}

	get combinedLiabilityCWT() {
		return (this.combinedLiability / this.declaredProduction) * 100;
	}

	get weightedClassPremiumBeforeSubsidy() {
		const grossPremium = this.hasClassQuote ? this.class.grossPremium : 0;

		return grossPremium * (1 - this.percentageComponentInsurance / 100);
	}

	get weightedComponentPremiumBeforeSubsidy() {
		const grossPremium = this.component ? this.component.grossPremium : 0;
		return grossPremium * (this.percentageComponentInsurance / 100);
	}

	get combinedPremiumBeforeSubsidy() {
		if (this.currentPractice && this.currentPractice.missingClass) {
			return this.component.grossPremium;
		}

		if (this.currentPractice && this.currentPractice.missingComponent) {
			return this.class.grossPremium;
		}

		return this.weightedClassPremiumBeforeSubsidy + this.weightedComponentPremiumBeforeSubsidy;
	}

	get weightedClassPremiumBeforeSubsidyCWT() {
		return (this.weightedClassPremiumBeforeSubsidy / this.classBasedPounds) * 100;
	}

	get weightedComponentPremiumBeforeSubsidyCWT() {
		return (this.weightedComponentPremiumBeforeSubsidy / this.componentBasedPounds) * 100;
	}

	get combinedPremiumBeforeSubsidyCWT() {
		return (this.combinedPremiumBeforeSubsidy / this.declaredProduction) * 100;
	}

	get weightedClassSubsidy() {
		const subsidy = this.class ? this.class.subsidy : 0;
		return subsidy * (1 - this.percentageComponentInsurance / 100);
	}

	get weightedComponentSubsidy() {
		const subsidy = this.component ? this.component.subsidy : 0;
		return subsidy * (this.percentageComponentInsurance / 100);
	}

	get combinedSubsidy() {
		if (this.currentPractice && this.currentPractice.missingClass) {
			return this.component.subsidy;
		}

		if (this.currentPractice && this.currentPractice.missingComponent) {
			return this.class.subsidy;
		}

		return this.weightedClassSubsidy + this.weightedComponentSubsidy;
	}

	get weightedClassSubsidyCWT() {
		return (this.weightedClassSubsidy / this.classBasedPounds) * 100;
	}

	get weightedComponentSubsidyCWT() {
		return (this.weightedComponentSubsidy / this.componentBasedPounds) * 100;
	}

	get combinedSubsidyCWT() {
		return (this.combinedSubsidy / this.declaredProduction) * 100;
	}

	get weightedClassPremiumAfterSubsidy() {
		const netPremium = this.class ? this.class.netPremium : 0;
		return netPremium * (1 - this.percentageComponentInsurance / 100);
	}

	get weightedComponentPremiumAfterSubsidy() {
		const netPremium = this.component ? this.component.netPremium : 0;
		return netPremium * (this.percentageComponentInsurance / 100);
	}

	get combinedPremiumAfterSubsidy() {
		if (this.currentPractice && this.currentPractice.missingClass) {
			return this.component.netPremium;
		}

		if (this.currentPractice && this.currentPractice.missingComponent) {
			return this.class.netPremium;
		}

		return this.weightedClassPremiumAfterSubsidy + this.weightedComponentPremiumAfterSubsidy;
	}

	get weightedClassPremiumAfterSubsidyCWT() {
		return (this.weightedClassPremiumAfterSubsidy / this.classBasedPounds) * 100;
	}

	get weightedComponentPremiumAfterSubsidyCWT() {
		return (this.weightedComponentPremiumAfterSubsidy / this.componentBasedPounds) * 100;
	}

	get combinedPremiumAfterSubsidyCWT() {
		return (this.combinedPremiumAfterSubsidy / this.declaredProduction) * 100;
	}
}
