import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='relative' ...attributes>\n\t<label for={{@uid}} class='absolute -top-19 left-0 text-xs whitespace-nowrap font-sans-semibold leading-5 text-gray-700'>Market Date</label>\n\t<OneWayDateMask\n\t\tid='{{@uid}}'\n\t\t@value={{this.formatDateValue}}\n\t\t{{did-insert (fn this.setupFlatpickr 'date')}}\n\t\t@update={{this.handleDateMaskInput}}\n\t\t@options={{hash inputFormat='mm/dd/yyyy' outputFormat='yyyy-mm-dd'}}\n\t\tplaceholder='mm/dd/yyyy'\n\t\tclass='border rounded focus:border-0 active:border-0 p-1 focus:ring active:ring ring-offset-yellow ring-yellow-200 ring-offset-0 outline-none w-40'\n\t/>\n\n</div>", {"contents":"<div class='relative' ...attributes>\n\t<label for={{@uid}} class='absolute -top-19 left-0 text-xs whitespace-nowrap font-sans-semibold leading-5 text-gray-700'>Market Date</label>\n\t<OneWayDateMask\n\t\tid='{{@uid}}'\n\t\t@value={{this.formatDateValue}}\n\t\t{{did-insert (fn this.setupFlatpickr 'date')}}\n\t\t@update={{this.handleDateMaskInput}}\n\t\t@options={{hash inputFormat='mm/dd/yyyy' outputFormat='yyyy-mm-dd'}}\n\t\tplaceholder='mm/dd/yyyy'\n\t\tclass='border rounded focus:border-0 active:border-0 p-1 focus:ring active:ring ring-offset-yellow ring-yellow-200 ring-offset-0 outline-none w-40'\n\t/>\n\n</div>","moduleName":"insurance-quoter/components/date-picker.hbs","parseOptions":{"srcName":"insurance-quoter/components/date-picker.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { DateTime } from 'luxon';
import flatpickr from 'flatpickr';

export default class DatePickerComponent extends Component {
	@tracked date;
	@tracked dateMasked;
	@tracked dateUnmasked;
	@tracked unmasked;
	@tracked masked;

	get formatDateValue() {
		// format existing Market Date
		let _dateMasked = this.dateMasked;
		let existingValue = this.args.value;

		if (existingValue) {
			_dateMasked = DateTime.fromFormat(existingValue, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
		}
		return _dateMasked;
	}

	@action
	update(unmasked, masked) {
		this.unmasked = unmasked;
		this.masked = masked;
	}

	// flatpickr instances
	dateFlatpickr = null;

	@action
	setupFlatpickr(dateStr) {
		const element = document.getElementById(this.args.uid);

		const onChangeFunc = function () {
			return function curryOnChangeFunc(selectedDates, dateStr) {
				this.dateUnmasked = dateStr;
				this.dateMasked = DateTime.fromFormat(dateStr, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
			};
		};

		this.dateFlatpickr = flatpickr(element, {
			dateFormat: 'm-d-Y',
			allowInput: true,
			onChange: onChangeFunc(dateStr),
			static: false,
		});

		if (this.dateUnmasked) {
			// set date in flatpickr instance with setDate instance method if existing set date value
			this.dateFlatpickr.setDate(this.dateUnmasked, false);
		}
	}

	@action
	handleDateMaskInput(unmasked, masked) {
		this.dateMasked = masked;
		this.dateUnmasked = unmasked;
		this.args.onChange(unmasked);
	}
}
