import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import { next } from '@ember/runloop';
import { mixpanelCustomTracking } from 'insurance-quoter/utils/mixpanel-tracking';

export default class DrpQuotesController extends Controller {
	@queryManager apollo;

	queryParams = [
		'stateAbbreviation',
		'stateName',
		'practiceCode',
		'classIIIPercentage',
		'componentWeightingFactor',
		'componentClassIIIPercentage',
		'protein',
		'butterfat',
		'salesEffectiveDate',
		'declaredPounds',
		'percentageComponentInsurance',
		'protectionFactor',
		'currentCoverageLevel',
	];

	@tracked stateAbbreviation = 'MI';
	@tracked stateName = 'Michigan';
	@tracked currentFullState = {};
	@tracked practiceCode = null;
	@tracked drpPractices = [];
	@tracked currentPractice = {};
	@tracked practices = [];
	@tracked classCoverageLevels = [];
	@tracked classIIIPercentage = 1;
	@tracked currentCoverageLevel = 95;
	@tracked componentCoverageLevels = [];
	@tracked butterfat = 3.5;
	@tracked protein = 3;
	@tracked componentClassIIIPercentage = 1;
	@tracked declaredPounds = 1000000;
	@tracked percentageComponentInsurance = 0;
	@tracked protectionFactor = 1.0;
	@tracked salesEffectiveDate = null;
	@tracked classBasedUpdating = false;
	@tracked componentBasedUpdating = false;
	@tracked quoteUpdating = false;
	@tracked editingDeclaredPounds = false;

	get inputDisabled() {
		return this.classBasedUpdating || this.componentBasedUpdating || this.quoteUpdating || this.editingDeclaredPounds;
	}

	get declaredProductionInputDisabled() {
		return this.classBasedUpdating || this.componentBasedUpdating || this.quoteUpdating;
	}

	get mixDisabled() {
		return this.currentPractice ? this.currentPractice.mixDisabled : null;
	}

	get effectiveClassIIIComponentPercentage() {
		if (this.currentPractice) {
			switch (this.currentPractice.componentRestrictions) {
				case '0':
					return 0;
				case '1':
					return 1;
				default:
					return this.componentClassIIIPercentage;
			}
		} else {
			return null;
		}
	}

	get effectiveClassIIIPercentage() {
		if (this.currentPractice) {
			switch (this.currentPractice.classRestrictions) {
				case '0':
					return 0;
				case '1':
					return 1;
				default:
					return this.classIIIPercentage;
			}
		} else {
			return null;
		}
	}

	@action
	updateState(state) {
		mixpanelCustomTracking('DRP - state dropdown used', {
			event: `Location changed to ${state.name}`,
		});
		this.currentFullState = state;
		this.stateAbbreviation = state.abbreviation;
		this.stateName = state.name;
	}

	@action
	updatePracticeCode(code) {
		this.practiceCode = code;
		this.currentPracticeId = code;
	}

	@action
	updateCurrentPractice(currentPractice) {
		this.currentPractice = currentPractice;
	}

	@action
	updateClassIIIPercentage(percentage) {
		mixpanelCustomTracking('DRP - Class Based Insurance used', {
			event: 'Class ratio percentage changed',
		});
		this.classIIIPercentage = Math.round((1 - percentage) * 100) / 100;
	}

	@action
	updateProtein(percentage) {
		mixpanelCustomTracking('DRP - Component Based Insurance used', {
			event: `Component protein changed to ${percentage}`,
		});
		this.protein = percentage;
	}

	@action
	updateButterfat(percentage) {
		mixpanelCustomTracking('DRP - Component Based Insurance used', {
			event: `Component butterfat changed to ${percentage}`,
		});
		this.butterfat = percentage;
	}

	@action
	updateComponentCoverageLevels(level) {
		this.componentCoverageLevels = level;
	}

	@action
	updateComponentClassIIIPercentage(percentage) {
		mixpanelCustomTracking('Component Based Insurance used', {
			event: `Component Class percentage changed`,
		});
		this.componentClassIIIPercentage = Math.round((1 - percentage) * 100) / 100;
		this.componentWeightingFactor = Math.round((1 - percentage) * 100) / 100;
	}

	@action
	updateDeclaredPounds(pounds) {
		mixpanelCustomTracking('DRP - Declared Quarterly Pounds used', {
			event: `Declared Quarterly Pounds changed to ${pounds}`,
		});
		this.declaredPounds = pounds;
	}

	@action
	updatePercentageComponentInsurance(percentage) {
		mixpanelCustomTracking('DRP - Insurance Mix used', {
			event: `Insurance Mix changed to ${percentage}% Component`,
		});
		this.percentageComponentInsurance = percentage;
	}

	@action
	updateProtectionLevel(level) {
		mixpanelCustomTracking('DRP - Protection Factor used', {
			event: `Protection Factor Level changed to ${level}`,
		});
		this.protectionFactor = level;
	}

	@action
	updateCurrentCoverageLevel(level) {
		mixpanelCustomTracking('DRP - Coverage Level used', {
			event: `Coverage Level changed to ${level}`,
		});
		this.currentCoverageLevel = +level;
	}

	@action
	updateClassBased(bool) {
		next(() => {
			this.classBasedUpdating = bool;
		});
	}

	@action
	updateComponentBased(bool) {
		next(() => {
			this.componentBasedUpdating = bool;
		});
	}

	@action
	editDeclaredPounds(bool) {
		this.editingDeclaredPounds = bool;
	}
	@action
	updateQuote(bool) {
		next(() => {
			this.quoteUpdating = bool;
		});
	}
}
