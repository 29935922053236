import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// import { inject as service } from '@ember/service';
// import ENV from 'insurance-quoter/config/environment';
import { queryManager } from 'ember-apollo-client';

export default class ApplicationController extends Controller {
	// @service session;
	// @service features;
	// @service permissions;

	@queryManager apollo;

	@tracked mobileNav = false;

	get getMobileNav() {
		const mn = this.mobileNav;
		return mn;
	}

	@action toggleMenu() {
		this.mobileNav = !this.mobileNav;
	}

	// @action
	// async invalidateSession() {
	//     await this.session.invalidate();
	//     window.location = `${ENV.APP.HOST}/oauth/logout?redirect_uri=${window.location.origin}`;
	// }

	// get idToken() {
	//     return jwtDecode(this.session.data.authenticated.id_token);
	// }

	// get userName() {
	//     return this.idToken.name;
	// }

	// get userEmail() {
	//     return this.idToken.email;
	// }

	// get isAuthenticated() {
	//     return this.session.isAuthenticated;
	// }
}
