import mixpanel from 'mixpanel-browser';
import ENV from 'insurance-quoter/config/environment';

const mixpanelPageView = (page) => {
	if (ENV.environment === 'production' || ENV.environment === 'test') {
		return mixpanel.track('Page View', {
			Page: page,
		});
	} else {
		return null;
	}
};

const mixpanelCustomTracking = (eventTitle, eventPropertyObj) => {
	if (ENV.environment === 'production' || ENV.environment === 'test') {
		return mixpanel.track(eventTitle, eventPropertyObj);
	} else {
		return null;
	}
};

export { mixpanelPageView, mixpanelCustomTracking };
