import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { setOwner } from '@ember/application';
import { DateTime } from 'luxon';

class FeedFuturesPrices {
	@service marketData;
	registeredSymbols = [];
	symbol = '';
	month = '';

	constructor(owner, month) {
		setOwner(this, owner);
		this.month = month;
		this.symbol = month.currentFuture.barchartSymbol;
		this.registerMonthSymbols(month.currentFuture.barchartSymbol);
	}

	get formattedFutureMonth() {
		const displayDT = DateTime.fromFormat(this.month.currentFuture.displayExpiresAt, 'yyyy-M-d');
		const futureMonth = displayDT.toLocaleString({
			year: 'numeric',
			month: 'short',
		});
		return futureMonth;
	}

	get price() {
		const productMarketDatum = this.marketData.getMarketDatum(this.symbol);

		return productMarketDatum?.lastPrice * this.month.displayFactor;
	}

	registerMonthSymbols(symbol) {
		if (!this.registeredSymbols.includes(symbol)) {
			this.marketData.register(symbol);
			this.registeredSymbols.push(symbol);
		}
	}

	willDestroy() {
		this.registeredSymbols.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});
	}
}

class Month {
	@tracked checked = true;
	@tracked price = 0;
	@tracked displayFactor = 0;
	@tracked currentFuture = null;
	@tracked monthYear = '';

	constructor(month) {
		this.checked = month.checked;
		this.price = month.price;
		this.displayFactor = month.displayFactor;
		this.currentFuture = month.currentFuture;
		this.monthYear = month.monthYear;
	}
}

export default class FeedBasisController extends Controller {
	@service MarketData;
	@tracked feedType = 'Corn';
	@tracked cashInput = 0;
	@tracked basisInput = 0;
	@tracked observeChecked = true;
	@tracked cornInTons = 0.028;
	@tracked cornInBushels = 1;

	feedTypeList = ['Corn', 'Soybean Meal'];
	monthsOutCount = 23;
	get products() {
		return this.model.Products;
	}

	get activeProduct() {
		return this.feedType === 'Soybean Meal' ? this.soybeanMealProduct : this.cornProduct;
	}

	get cornProduct() {
		return this.products.find((product) => product.slug === 'grain-corn');
	}

	get soybeanMealProduct() {
		return this.products.find((product) => product.slug === 'grain-soybean-meal');
	}

	// Build the table and monthObj by delivery month starting with the current month until this.monthsOutCount.
	get deliveryMonths() {
		const owner = getOwner(this);
		const now = DateTime.now();
		let monthsArray = [];

		for (let i = 0; i <= this.monthsOutCount; i++) {
			let monthObj = {};
			let futuresList = [];
			let nextDate = DateTime.fromISO(now).plus({ months: i });

			monthObj.displayFactor = this.activeProduct.displayFactor;

			const monthYear = nextDate.toLocaleString({
				year: 'numeric',
				month: 'short',
			});

			monthObj.monthYear = monthYear;

			const el = document.getElementById(`${monthYear}-delivery-month-checkbox`);
			monthObj.checked = el?.checked ?? true;
			// Get the nearest future month to the delivery month -dmw
			this.activeProduct.CurrentFutures.map((currentFuture) => {
				// Using DateTime we can perform the lte operation -dmw
				const displayDT = DateTime.fromFormat(currentFuture.displayExpiresAt, 'yyyy-M-d');
				const monthDT = DateTime.fromFormat(monthYear, 'MMM yyyy');

				//Create a list of current futures. June through September use July pricing. -dmw
				if (monthDT.month > 5 && monthDT.month < 10 && displayDT.month === 7 && monthDT.year === displayDT.year) {
					futuresList.push(currentFuture);
				} else if (monthDT <= displayDT) {
					futuresList.push(currentFuture);
				}
			});

			// We only care about the first future in the futureList because that will either be the current future for the matching delivery month or the next future available. - dmw
			monthObj.currentFuture = futuresList[0];
			monthsArray.push(monthObj);
		}

		return monthsArray.map((month) => new FeedFuturesPrices(owner, new Month(month)));
	}

	get checkedMonths() {
		return this.observeChecked === true ? this.deliveryMonths.filter((e) => e.month.checked === true) : null;
	}

	get allMonthsChecked() {
		return this.observeChecked === true && this.deliveryMonths.every((e) => e.month.checked === true);
	}

	// all pricing logic in the below getters are from the 'Basis Calculator' sheet provided by Jake Kingsley
	get averageBushelPrice() {
		const prices = this.checkedMonths.map((month) => month.price);
		const length = prices.length;
		let sum = prices.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

		return sum / length;
	}

	get averageTonPrice() {
		if (this.activeProduct.name === 'Soybean Meal') {
			const prices = this.checkedMonths.map((month) => month.price);
			const length = prices.length;
			let sum = prices.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

			return sum / length;
		} else if (this.activeProduct.name === 'Corn') {
			return this.averageBushelPrice / 2.8 / this.cornProduct.displayFactor;
		} else {
			return null;
		}
	}
	// 35.714 bushels of corn in an American short ton (2000lb) - dmw
	get basisPerBushel() {
		return +this.cashInput / 35.714 - this.averageBushelPrice;
	}

	get basisPerTon() {
		return this.activeProduct.name === 'Soybean Meal'
			? +this.cashInput - this.averageTonPrice
			: +this.cashInput - this.averageBushelPrice / this.cornProduct.displayFactor / 2.8;
	}

	get cashPerBushel() {
		return +this.basisInput + this.averageBushelPrice;
	}

	get cashPerTon() {
		return this.activeProduct.name === 'Soybean Meal'
			? +this.basisInput + this.averageTonPrice
			: (+this.basisInput + this.averageBushelPrice) / 2.8 / this.cornProduct.displayFactor;
	}

	@action
	setAllMonths(event) {
		const value = event.target.checked;
		const checkboxes = document.querySelectorAll('input[id*="-delivery-month-checkbox"]');
		checkboxes.forEach((checkbox) => {
			checkbox.checked = value;
		});
		this.observeChecked = true;
	}

	@action
	updateFeedType(e) {
		e.target.id === 'soybeanMealButton' ? (this.feedType = 'Soybean Meal') : (this.feedType = 'Corn');
	}

	@action
	checked() {
		this.observeChecked = true;
	}

	@action
	updateCashInput(value) {
		this.cashInput = value;
	}

	@action
	updateBasisInput(value) {
		this.basisInput = value;
	}

	@action
	updateCornInTons(value) {
		this.cornInBushels = (parseFloat(value) / 0.028).toFixed(1);
	}

	@action
	updateCornInBushels(value) {
		this.cornInTons = (parseFloat(value) * 0.028).toFixed(2);
	}
}
