import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';

export default class LrpScenarioQuote {
	@tracked endDate;
	@tracked headCount;
	@tracked targetWeightCWT;
	@tracked coveragePrice;
	@tracked rate;
	@tracked insuredSharePercentage;

	constructor(scenario, headCount, targetWeightCWT) {
		this.scenario = scenario;
		this.endDate = scenario.FormattedendDate;
		this.expectedValue = scenario.expectedValue;
		this.insuredSharePercentage = scenario.insuredSharePercentage;
		this.coveragePrice = scenario.coveragePrice;
		this.rate = scenario.rate;
		this.headCount = headCount;
		this.targetWeightCWT = targetWeightCWT;
	}

	get formattedEndDate() {
		// this formats the date ex. September 19, 2021
		let date = this.endDate;
		let dt = DateTime.fromFormat(date, 'M/dd/yyyy');

		return dt.toLocaleString({
			month: 'long',
			day: 'numeric',
			year: 'numeric',
		});
	}

	get insuredValue() {
		// The Insured Value = Number of Head multiplied by the Target Weight (live weight, in cwt. lean weight for swine)multiplied by the Coverage Price multiplied by Ownership Share. The Insured Value is rounded to the nearest whole dollar.
		// assuming ownership share is 1.0
		let value = this.headCount * this.targetWeightCWT * this.coveragePrice;

		return Math.round(value);
	}

	get totalPremium() {
		// The Total Premium = Insured Value multiplied by the Rate. Total Premium is rounded to the nearest whole dollar.
		let value = this.insuredValue * this.rate;

		return Math.round(value);
	}

	get subsidy() {
		// The Subsidy = Total Premium multiplied by the Subsidy Rate. The Subsidy Rate varies by the coverage level . Subsidy is rounded to the nearest whole dollar.

		//Subsidy rates
		// 95-100 - 35
		// 90-94.99 - 40
		// 85-89.99 - 45
		// 80-84.99 - 50
		// 70-79.99 - 55

		let value = this.totalPremium * 0.5;

		return Math.round(value);
	}

	get producerPremium() {
		// The Producer Premium = Total Premium minus the Subsidy. Producer Premium will always be a whole number.
		let value = this.totalPremium - this.subsidy;

		return value;
	}

	get producerCostPerCWT() {
		// Subsidized cost per CWT = (Coverage Price * Rate) * (1 - Subsidy percentage)
		// let value = this.coveragePrice * this.rate * (1 - 0.5);
		//Subsidy rates
		// 95-100 - 35
		// 90-94.99 - 40
		// 85-89.99 - 45
		// 80-84.99 - 50
		// 70-79.99 - 55
		let unsubsidizedPerCWT = this.coveragePrice * this.rate;
		let value = unsubsidizedPerCWT * (1 - 0.5);

		return value.toFixed(2);
	}

	get producerCostPerHead() {
		//Producer Cost Per Head = Producer Total Premium / Headcount
		let value = this.producerPremium / this.headCount;

		return value;
	}
}
