import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface UiPrimaryMenuItemArgs {
	linkRoute: string;
	activeRoute?: string;
	activeCheck?: () => boolean;
}

export default class UiPrimaryMenuItem extends Component<UiPrimaryMenuItemArgs> {
	@service router: any;

	get isActive(): boolean {
		if (this.args.activeCheck) {
			return this.args.activeCheck();
		} else if (this.args.activeRoute) {
			return this.router.currentRouteName === this.args.activeRoute;
		} else {
			return this.router.currentRouteName === this.args.linkRoute;
		}
	}
}
