import EmberRouter from '@ember/routing/router';
import config from 'insurance-quoter/config/environment';

export default class Router extends EmberRouter {
	location = config.locationType;
	rootURL = config.rootURL;
}

Router.map(function () {
	this.route('drp-quotes');
	this.route('lrp-quotes');

	this.route('notifications', function () {
		this.route('lrp-create-subscription');
		this.route('lrp-modify-subscription');
		this.route('unsubscribe');
		this.route('subscribe-confirmation', {
			path: '/subscribe-confirmation/:product',
		});
		this.route('modify-confirmation', {
			path: '/modify-confirmation/:product',
		});
		this.route('drp-create-subscription');
		this.route('drp-modify-subscription');
	});
	this.route('feed-basis');
});
