import { DateTime } from 'luxon';
import EndDate from 'insurance-quoter/models/lrp-end-date';

export default class LrpBeforeEndDate extends EndDate {
	constructor(coverage) {
		super(coverage);
	}

	get endDateBeforeMarketDate() {
		let findFirstBeforeDate = this.coverage.find((e) => e.FormattedendDate);

		let dt = DateTime.fromFormat(findFirstBeforeDate.FormattedendDate, 'M/dd/yyyy');

		return dt.toLocaleString({
			month: 'long',
			day: 'numeric',
			year: 'numeric',
		});
	}

	get firstExpectedValue() {
		let findFirstBeforeExpectedValue = this.coverage.find((e) => e.expectedValue);

		return findFirstBeforeExpectedValue.expectedValue;
	}
}
